import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'
import './../Styles/openindividual.css'
import {
  
  Link,
} from "react-router-dom";
function ZeusBankShareholdingAccount() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='ZeusBankShareholdingAccount'>
    <Navbar />
   <div className="container py-5">

   <h3 className='fw-bolder mt-4' >ZeusBank Shareholding Account</h3>
   <h6 className='fw-bolder mt-4' >Secure Your Future with ZeusBank Shareholding Account</h6>
   

       <h5 className='fw-bolder mt-4' >Introduction to ZeusBank Shareholding Account</h5>
       <p>The First 10 to 20 Million PayZeus Subscribers/Account-holders will have an opportunity to Become Shareholders of the ZeusBank, the bank behind PayZeus, up to a maximum of US$25 per subscriber, or up to the maximum amount allowed under the rules and regulations of the central bank and the stock market of your country, if that is lower than $25 per individual, by allocating a portion of their funds into the Capital Account option provided in the Application.</p>
         <p>Are you looking for a reliable way to grow your wealth? The ZeusBank Shareholding Account is designed for you. This account offers numerous benefits.</p>
         <h5 className='fw-bolder mt-4' >Benefits of a ZeusBank Shareholding Account</h5>
         <p>Opening a ZeusBank Shareholding Account provides multiple advantages. Firstly, it allows you to hold and manage your shares seamlessly</p>

         
         <p>Our dedicated support team is always ready to assist you. Whether you have questions about your ZeusBank Shareholding Account or need investment advice, we are here to help.</p>
        <p>The ZeusBank Shareholding Account is a smart choice for those looking to invest wisely. With easy management, competitive rates, and robust security, it’s an ideal option for growing your wealth. Open your account today and take the first step towards a secure financial future.
For more information, visit our website or contact our customer support team. Start investing with ZeusBank and secure your future now.
</p>


        </div>

   <Footer />
</div>
  )
}

export default ZeusBankShareholdingAccount;
