import React from "react";
import { useState,useEffect } from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import { InquiryApi } from "../Apis/AuthApis";
import "./../Styles/feedback.css";

function Feedback() {

  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [subject, setSubject] = useState('')
  const [message,setMessage] = useState('')


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit =(e)=>{
    e.preventDefault()
    let formdata ={
      "name": firstName,
      "email": email,
      "phone": mobile,
      "subject": subject,
      "message": message
    }
    InquiryApi(formdata, setFirstName, setEmail, setMessage, setMobile, setSubject)
  }

  return (
    <div id="Feedback">
      <Navbar />

      <div className="container mb-4 py-5">
        <div className="row">
          <div className="col-lg-7">
          <h3 className="fw-bolder mt-4 ">Contact Us</h3>
          <p className="Contact-bg ">
            <strong>PayZeus</strong>
            <br></br> C/O Macroeconsulting Ltd  <br></br> 18764 Devonshire Street <br></br>
            Northridge, CA 91324
            <br></br> USA<br></br> Info@PayZeus.net</p>
</div>
</div>
</div>
<div className="container mb-4 py-5">
        <div className="row">
          <div className="col-lg-7">
          <hr></hr>
            <h4 className="fw-bolder mt-5 ">Provide Feedback & Ask {firstName}</h4>

            
            <form
               onSubmit={(e)=>handleSubmit(e)}
              // action="https://formspree.io/f/mzblvzyp"
              // method="POST"
              className="payzeusform"
            >
              <label for="fname">Name:</label>
              <br />
              <input value={firstName} onChange={(e)=>setFirstName(e.target.value)} type="text" name="name" required />
              <br />

              <label for="lname">Email:</label>
              <br />
              <input value={email} onChange={(e)=>setEmail(e.target.value)}  type="email" name="email" required  />
              <br />

              <label for="fname">Phone no. :</label>
              <br />
              <input value={mobile} onChange={(e)=>setMobile(e.target.value)}  type="number" name="contact" required />
              <br />

              <label for="fname">Subject:</label>
              <br />
              <input value={subject} onChange={(e)=>setSubject(e.target.value)}  type="text" name="subject" required />
              <br />

              <label for="fname">You Message:</label>
              <br />
              <textarea
                name="message"
                id=""
                cols="30"
                rows="5"
                required
                value={message} onChange={(e)=>setMessage(e.target.value)} 
              ></textarea>
              <br />
              <button type="submit">Submit</button>
            </form>
          </div>
          <div className="col-lg-5">
            <img className="img-fluid" src="/images/feedback.jpg" alt="" />
            <br></br>
           
          </div>
        </div>

      </div>

      <Footer />
    </div>
  );
}

export default Feedback;
