import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

import './../Styles/openindividual.css'

function OffersAttractiveRewards() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='OffersAttractiveRewards'>
    <Navbar />
   <div className="container py-5">

   <h3 className='fw-bolder mt-4' >PayZeus Offers Attractive “Rewards/Points/Offers”</h3>

  <p>PayZeus Individual Account Holders will receive points and rewards, including cash-back, on their purchases using their PayZeus Digital Debit Card and Digital Credit Cards from participating stores! They could also benefit from redeeming various generous promotional offers made by the participating merchants. These vouchers and promotion offers are posted on both PayZeus Application and PayZeus website, and differ from county-to-country, city-to-city, and even location-to-location, and include targeted, using a custom-made AI program, offers based on the individual’s shopping preferences.</p>

<p>Welcome to PayZeus, where we believe in rewarding our valued customers for their loyalty and support. With our attractive rewards, points, and offers program, PayZeus individual account holders can enjoy a host of benefits simply by using their PayZeus Digital Debit Card and Digital Credit Cards at participating stores.</p>

   <h5 className='fw-bolder mt-4' >Rewarding Loyalty</h5>
   <p>As a PayZeus account holder, you'll earn points and rewards, including cash-back, on your purchases made with your PayZeus cards. These rewards are our way of saying thank you for choosing PayZeus for your banking needs.</p>
   <h5 className='fw-bolder mt-4' >Generous Promotional Offers</h5>
   <p>In addition to earning points, you'll also have access to a variety of promotional offers from participating merchants. These offers include discounts, vouchers, and special deals tailored to your preferences.</p>
   <h5 className='fw-bolder mt-4' >Customized Offers</h5>
   <p>At PayZeus, we understand that every customer is unique. That's why we use a custom-made AI program to offer targeted promotions based on your individual shopping preferences. Whether you're a frequent traveler, a fashion enthusiast, or a foodie, we have offers designed just for you.</p>
        
   <h5 className='fw-bolder mt-4' >Accessible Offers</h5>
   <p>You can access all our rewards, points, and promotional offers directly through the PayZeus application and website. These offers may vary from country to country, city to city, and even location to location, ensuring that you always have access to the best deals wherever you are.</p>
   <h5 className='fw-bolder mt-4' >Join PayZeus Today</h5>
   <p>Start enjoying the benefits of PayZeus rewards and offers today. Sign up for a PayZeus account and experience the convenience of banking with added perks. </p>
        </div>
   <Footer />
</div>
  )
}

export default OffersAttractiveRewards;
