import React from "react";
import { BiRightArrowCircle } from "react-icons/bi";
import "../Styles/voucher.css";
import {
  
  Link,
} from "react-router-dom";
function Voucher() {
  return (
    <div className="voucher">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-sm-start">
            <div className="voucher-image">
              <img src="/images/voucher.jpg" alt="" />
            </div>
          </div>

          <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-sm-start mt-sm-3 mt-md-0 mt-0">
            <div className="voucher-main w-sm-75 w-100 w-sm-0">
              <h2 className="mb-3">PayZeus No Recharge Fee</h2>
             <p>PayZeus offers several convenient options to top-up your account securely, efficiently, and completely free of charge.</p>
              <p>These options cater to the full range of PayZeus account-holders—from the financial savvy with access to major credit cards and or checking accounts at the commercial banks, to the unbanked.</p>

<h5 className="fw-bolder mt-4">
        You can top-up your account using:
        </h5>
        <ul>
          <li className="p-2 m-0">Your major credit cards, including Visa, Mastercard, AmericanExpress, or
          Discover, by adding your favorite card(s) to your PayZeus eWallet;</li>
          <li className="p-2 m-0">Your current/checking account at a commercial bank, also by adding it to
          your PayZeus eWallet;</li>
          <li className="p-2 m-0">Scanning the QR where ever the PayZeus QR sign is displayed, at authorized
          stores, petrol stations, and street stalls;</li>
          <li className="p-2 m-0">Cash at authorized stores/stalls or from on-foot-vendors in the rural areas, to
either use an approved recharge machine, or by purchasing PayZeus
Prepaid/Scratch Cards to top-up your PayZeus account.</li>
          
        </ul>
              { <div className="my-4">

                <button className="deposite-learn-btn">
                 { /*<BiRightArrowCircle /> */}
                  <Link to="/payZeus-no-recharge-fee" style={{ color: '#fff', textDecorationLine:'none' }}> Learn More</Link>
                </button>
                               
              </div> }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Voucher;
