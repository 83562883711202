import React,{useEffect} from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import '../Styles/rechargepage.css'

import {
  
  Link,
} from "react-router-dom";
function AuthorizedStreetVendors() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Navbar />


      <div className="container pt-5">
      
      <div className="row">
        <div className="col-md-12">
        <h3 className="fw-bolder mt-4">Authorized On-Foot Vendors</h3>
        <p>At PayZeus, we are dedicated to making digital banking accessible and convenient for
everyone, including those living in remote areas with limited facilities. We offer the
option to recharge your PayZeus account using PayZeus Prepaid/Scratch Cards
through our network of authorized on-foot vendors and stalls with no-access to on-site
Internet connection. This method ensures that even those without access to traditional
banking services can easily top up their accounts and enjoy the benefits of our digital
platform, while creating income-generation opportunities for the vetted rural poor and
unemployed.</p>
        
        <h5 className='fw-bolder mt-4' >How to Recharge Your PayZeus Account with Authorized Street Vendors?</h5>
        <strong>Recharging your PayZeus Account using a Prepaid/Scratch Cards is a simple process. Here’s how you can do it:</strong>
       
        <ul>
          <li className="p-2 m-0">Locate and visually identify the authorized street stalls or on-foot vendors in your
          village/rural area; they carry visibly a PayZeus Identification Card.
</li>
          <li className="p-2 m-0">Visit the Vendor: Go to the chosen vendor with the amount of cash you wish to
          add to your PayZeus account.
</li>
          <li className="p-2 m-0">Specify the denomination of the Prepaid/Scratch card you wish to purchase; the
prepaid cards are offered in three different denominations to ensure flexibility and
convenience.
</li>
          <li className="p-2 m-0">Hand Over the Cash: Give the cash to the vendor and specify the amount and
          denomination of the cards you wish to purchase.
</li>
<li className="p-2 m-0">Scratch the back of the Prepaid Card at your privacy and convenience when you
are ready to top-up your PayZeus account.
</li>
<li className="p-2 m-0">
Enter the revealed Serial Number on the back of the scratch card into your account dashboard.
</li>
<li className="p-2 m-0">Check your PayZeus account to ensure it fully reflects your transactions.
</li> 
<li className="p-2 m-0">Save as proof of deposit, or properly discard, the used prepaid cards.
</li>  
        </ul>
              
        </div>

        
      </div>
      
      
      </div>






      <div className="container pb-5">
        
        <h5 className="fw-bolder mt-4">
        Benefits of Using PayZeus Authorized On-Foot Vendors and Street Stalls
        </h5>
        
        <ul>
          <li className="p-2 m-0">Wide Reach: Our authorized on-foot vendors cover the entire community,
          ensuring broad accessibility.
</li>
          <li className="p-2 m-0">Security and Trust: Transactions through authorized on-foot vendors and street
stalls in the rural areas are secure, with each vendor undergoing a rigorous
verification process.
</li>
          <li className="p-2 m-0">Immediate Fund Availability: Once the account recharge transaction is completed
by entering the Prepaid Card number, the funds are available in your PayZeus
account instantly, enabling you to make payments and transfers without delay.
</li>
         

        </ul>
        <h5 className="fw-bolder mt-4">
        Security Measures
        </h5>
        <p>At PayZeus, we prioritize the security of your transactions. Our authorized street
vendors follow strict protocols to ensure you are offered original and valid PayZeus
Prepaid/Scratch cards so when they added to your PayZeus account they are verified
and credited instantly:</p>
        <ul>
          <li className="p-2 m-0">Verified Vendors: Only authorized and visibly identifiable on-foot vendors and
          street stalls can offer PayZeus Prepaid/Scratch Cards.</li>
          <li className="p-2 m-0">Instant Notifications: You will receive immediate confirmation of your recharge
          after entering a valid prepaid card number into PayZeus recharge panel.</li>
          <li className="p-2 m-0">Transaction Receipts: It is advised that you always keep your scratched cards as
          proof of your transaction and for record-keeping.</li>
        
          
        </ul>
      </div>

      
      <Footer />
    </div>
  );
}

export default AuthorizedStreetVendors;
