import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

import './../Styles/openindividual.css'

function ContactUs() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='ContactUs'>
    <Navbar />
   <div className="container py-5">

   <h3 className='fw-bolder mt-4' >Contact Us</h3>
   
   <h5 className='fw-bolder mt-4' >PayZeus</h5>
<p><br></br>
c/o Macroeconsulting Ltd <br></br>
CA 91324 <br></br>
USA<br></br>
Info@PayZeus.net
</p>

        </div>
   <Footer />
</div>
  )
}

export default ContactUs;
