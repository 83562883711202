import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'
import './../Styles/openbusiness.css'

function OpenBusinessAccount() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='OpenBusinessAccount'>
    <Navbar />
   <div className="container py-5">
       <h3 className='fw-bolder mt-4' >To Open a Business Account</h3>
       <ul className='open_business_account_order_list' >
          <li className='p-2 m-0' >Select your Country (dropdown list of the countries where PayZeus is operational)</li>
          <li className='p-2 m-0' >Select your preferred language (dropdown of the languages offered in various countries, starting with English, Hindi, Urdu, Spanish, Chinese, Arabic, etc.)</li>
        
       </ul>



       <h5 className='fw-bolder mt-4' >How to Open a Business Account</h5>
       <ul className='open_business_account_order_list' >
          <li className='p-2 m-0' >Download PayZeus Business Application</li>
          <li className='p-2 m-0' >QR to Download the PayZeus Business Application</li>
          <li className='p-2 m-0' >Enter your Registered Business Mobile Number (this will be your unique bank account number worldwide)</li>
          <li className='p-2 m-0' >Enter the Name of Your Business as per your Trade/Business License</li>
          <li className='p-2 m-0' >Upload a copy of Your Trade/Business License</li>
          <li className='p-2 m-0' >Enter Your Full Business/Mailing Address</li>
          <li className='p-2 m-0' >Enter Your Tax ID Number</li>
          <li className='p-2 m-0' >Enter the OTP Number you will receive on your mobile (this OTP is valid for 10 minutes)</li>
          <li className='p-2 m-0' >Record Your Voice when prompted to secure your access (Follow the steps)</li>
          
       </ul>



   </div>
   <Footer />
</div>
  )
}

export default OpenBusinessAccount;
