import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'
import './../Styles/openindividual.css'

function DigitalBanking() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='DigitalBanking'>
    <Navbar />
   <div className="container py-5">

   <h3 className='fw-bolder mt-4' >Digital Banking</h3>
   <h5 className='fw-bolder mt-5' >Revolutionizing Financial Transactions with Digital Banking</h5>
 
   
       <p>In today's digital age, digital banking is essential for modern financial services. At PayZeus, we offer innovative solutions that meet the evolving needs of individuals and businesses worldwide. Our digital banking services, including virtual payment systems, e-wallets, and digital currency options, provide unparalleled convenience and security.</p>
        
       <h5 className='fw-bolder mt-4' >The Future of Financial Services: Digital Banking</h5>
        <p>Digital banking has transformed how we manage our finances. PayZeus offers a seamless banking experience that is both efficient and secure, allowing users to perform various transactions from anywhere.
        </p>

        <h5 className='fw-bolder mt-4' >Virtual Payment Systems: Convenience at Your Fingertips</h5>
        <p>Our virtual payment systems enable users to conduct transactions effortlessly. Whether paying bills, shopping online, or transferring money, PayZeus ensures fast, secure, and convenient transactions. 
        </p>
        <h5 className='fw-bolder mt-4' >E-Wallets: Your Digital Financial Companion</h5>
<p>
An e-wallet from PayZeus is more than just a digital wallet; it’s a comprehensive financial management tool. Users can store multiple payment methods, manage funds, and track spending with ease. Our e-wallets have robust security features, ensuring your financial information is always protected.
</p>
<h5 className='fw-bolder mt-4' >Real-Time Payments and Digital Cards</h5>
<p>

With our real-time payment (RTP) system, transactions are processed instantly. PayZeus also offers digital debit and credit cards with instant approval. Our virtual debit cards and virtual credit cards are perfect for online shopping, equipped with advanced security features, ensuring safe transactions.

</p>
<h5 className='fw-bolder mt-4' >Global Reach and Security</h5>
<p>

We employ state-of-the-art security measures, including encryption and real-time monitoring, to protect your financial information.

</p>

<h5 className='fw-bolder mt-4' >Join the Digital Banking Revolution with PayZeus</h5>
<p>

PayZeus is at the forefront of the digital banking revolution. Our commitment to excellence and customer satisfaction sets us apart. Join us today and experience the future of banking.

</p>
        </div>
   <Footer />
</div>
  )
}

export default DigitalBanking;
