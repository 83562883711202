import React,{useEffect} from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import '../Styles/rechargepage.css'

import {
  
  Link,
} from "react-router-dom";

function RechargePage() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Navbar />




      <div className="container pt-5">
      
      <div className="row">
        <div className="col-md-8">
        <h3 className="fw-bolder mt-4">Recharging Your PayZeus Account </h3>
        <p>PayZeus offers several convenient options to top-up your account securely,
        efficiently, and completely free of charge.</p>

        <p>These options cater to the full range of PayZeus account-holders—from the financial
savvy with access to major credit cards and or checking accounts at the
commercial banks, to the unbanked.</p>
        </div>

        
      </div>
      
      
      </div>






      <div className="container pb-5">
        
        <h5 className="fw-bolder mt-4">
        You can top-up your account using:
        </h5>
        <ul>
          <li className="p-2 m-0">Your major credit cards, including Visa, Mastercard, AmericanExpress, or
          Discover, by adding your favorite card(s) to your PayZeus eWallet;</li>
          <li className="p-2 m-0">Your current/checking account at a commercial bank, also by adding it to
          your PayZeus eWallet;</li>
          <li className="p-2 m-0">Scanning the QR where ever the PayZeus QR sign is displayed, at authorized
          stores, petrol stations, and street stalls;</li>
          <li className="p-2 m-0">Cash at authorized stores/stalls or from on-foot-vendors in the rural areas, to
either use an approved recharge machine, or by purchasing PayZeus
Prepaid/Scratch Cards to top-up your PayZeus account.</li>
          
        </ul>

      </div>
      <Footer />
    </div>
  );
}

export default RechargePage;
