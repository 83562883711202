import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

import './../Styles/openindividual.css'
import {
  
  Link,
} from "react-router-dom";

function Privacy() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='Privacy'>
    <Navbar />
   <div className="container py-5">

   <h3 className='fw-bolder mt-4' >Privacy Policy</h3>
   <h6 className='fw-bolder mt-4' >Effective Date: 8/8/2024</h6>
<p>PayZeus is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information.</p>
<h6 className='fw-bolder mt-4' >1. Information We Collect</h6>
<p>We collect information that you provide to us when you create an account, use our services, or contact us for support. This may include your name, your date of Birth, your address, email address, phone number, payment information, and transaction details.</p>
       
<h6 className='fw-bolder mt-4' >2. How We Use Your Information</h6>
<p>We use your information to provide, maintain, and improve our services, process transactions, communicate with you, and comply with legal obligations/ regulatory requirements.</p>
<h6 className='fw-bolder mt-4' >3. Sharing Your Information</h6>
<p>We do not share your personal information with third parties except as necessary to provide our services, comply with the law, or protect our rights.</p>
<h6 className='fw-bolder mt-4' >4. Data Security</h6>
<p>We implement robust security measures to protect your personal information from unauthorized access, disclosure, or alteration.</p>
<h6 className='fw-bolder mt-4' >5. Your Rights</h6>   
<p>You have the right to access, correct, or delete your personal information. You can manage your preferences by logging into your account or contacting us directly.</p>
       
<h6 className='fw-bolder mt-4' >6. Changes to This Policy</h6>
<p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website.</p>
<h6 className='fw-bolder mt-4' >7. Contact Information</h6>
<p>If you have any questions about this Privacy Policy, please contact us at info@payzeus.net.</p>
        </div>
   <Footer />
</div>
  )
}

export default Privacy;
