import React from "react";
import '../Styles/bannersecond.css'
import {
  
  Link,
} from "react-router-dom";
import { MdFormatTextdirectionRToL } from "react-icons/md";


function bannerSecond() {
  return (
    <div className="bannersecond">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <div className="bannersecond-main ">
              <h2 className="mb-3" >
                Your Registered Mobile Number is Your Unique PayZeus Bank Account Number -
                <span style={{color:'#af8d56'}} >  Worldwide!</span>
              
              </h2>
              <p>Your registered mobile number, either on prepaid or post-paid plans, excluding the prefix 00, or + sign, and including the country code, the mobile carrier code, without the prefix “0”, in your country, and your full 7 or 8 digits mobile number, will be your unique bank account number.  All incoming transfers and outgoing payments and all your unique PayZeus banking services will be identified with this account number!</p>
           
              <div className="my-4">
                <button className="bannersecond-learn-btn">
                <Link to="/registered-mobile-number" style={{ color: 'white',textDecorationLine:'none' }}>Read More</Link>
                </button>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default bannerSecond;
