import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

import './../Styles/openindividual.css'
import {
  
  Link,
} from "react-router-dom";

function LegalPage() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='LegalPage'>
    <Navbar />
   <div className="container py-5">

   <h3 className='fw-bolder mt-4' >Legal Notice</h3>
   <h6 className='fw-bolder mt-4' >Effective Date: 8/8/2024</h6>
<p>This Legal Notice governs the use of PayZeus services and website.</p>
<h6 className='fw-bolder mt-4' >1. Intellectual Property</h6>
<p>All content, trademarks, and data on this website, including but not limited to text, designs, graphics, and logos, are the property of PayZeus and are protected by intellectual property laws.</p>
       
<h6 className='fw-bolder mt-4' >2. Disclaimer</h6>
<p>PayZeus provides its services on an "as is" basis and makes no representations or warranties of any kind, express or implied, regarding the operation of our services or the information, content, materials, or products included.</p>
<h6 className='fw-bolder mt-4' >3. Limitation of Liability</h6>
<p>PayZeus shall not be liable for any damages of any kind arising from the use of our services, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>
<h6 className='fw-bolder mt-4' >4. External Links</h6>
<p>Our website may contain links to third-party websites. PayZeus is not responsible for the content or privacy practices of these external sites.</p>
<h6 className='fw-bolder mt-4' >5. Governing Law</h6>   
<p>This Legal Notice is governed by the laws of the State of California, USA. Any disputes arising from the use of our services or website will be resolved in the courts of California.</p>
       
<h6 className='fw-bolder mt-4' >6. Contact Information</h6>
<p>If you have any questions about this Legal Notice, please contact us at info@payzeus.net.</p>

        </div>
   <Footer />
</div>
  )
}

export default LegalPage;
