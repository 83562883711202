import React,{useEffect} from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import '../Styles/rechargepage.css'

import {
  
  Link,
} from "react-router-dom";
function AuthorizedStores() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Navbar />


      <div className="container pt-5">
      
      <div className="row">
        <div className="col-md-12">
        <h3 className="fw-bolder mt-4">Authorized Stores</h3>
        <strong>Recharge Your PayZeus Account at Authorized Stores</strong>
        <p>At PayZeus, we are dedicated to making digital banking accessible and convenient for
everyone. We offer the option to recharge your PayZeus account using cash or
prepaid/scratch cards through our network of authorized stores and stalls. This method
ensures that even those without access to traditional banking services can easily top up
their accounts and enjoy the benefits of our digital platform.</p>
        <h5 className='fw-bolder mt-4' >Why Choose Authorized Stores for Recharge?</h5>
        <strong>Recharging your PayZeus account at authorized stores offers numerous benefits:</strong>
       
        <ul>
          <li className="p-2 m-0">Accessibility: With a vast network of authorized stores and stalls in your city/town,
you can easily find a recharge point near you, making it convenient for both
urban and rural users.</li>
          <li className="p-2 m-0">Trusted Partners: PayZeus collaborates with reputable local businesses,
          ensuring that your transactions are handled securely and efficiently.</li>
          <li className="p-2 m-0">Instant Recharge: Using approved electronic recharge machines, funds are
credited to your PayZeus account instantly, allowing you to use them
immediately for various transactions. You also have the option of purchasing
PayZeus Prepaid/Scratch Cards at these outlets and recharge your account at
your convenience.</li>
          <li className="p-2 m-0">Personal Assistance: Store staff can assist you with the recharge process,
          making it easy even for those who are not tech-savvy.</li>
          
        </ul>
              
        </div>

        
      </div>
      
      
      </div>






      <div className="container pb-5">
        
        <h5 className="fw-bolder mt-4">
        How to Recharge at Authorized Stores?
        </h5>
        <strong>Recharging your PayZeus account at an authorized store is simple and
        straightforward. Follow these steps:</strong>
        <ul>
          <li className="p-2 m-0">Locate an Authorized Store: Use the PayZeus website or mobile app to find the
          nearest authorized store in your area.
</li>
          <li className="p-2 m-0">Visit the Store: Go to the selected authorized store with the amount of cash you
          wish to recharge.
</li>
          <li className="p-2 m-0">Provide Your PayZeus Account Details: If you prefer the recharge method using the approved electronic machine, inform the store staff of your registered PayZeus mobile number, which acts as your account identifier.  Otherwise, ask for the PayZeus Prepaid/Scratch cards.

</li>
          <li className="p-2 m-0">Hand Over the Cash: Hand over the cash to the vendor and specify the amount
          you want to recharge.
</li>
<li className="p-2 m-0">Confirm the Transaction: The store representative will process your request, and
the funds will be credited to your PayZeus account instantly if an electronic
recharge machine is used. You will receive a receipt as proof of the transaction.

</li>

        </ul>
        <h5 className="fw-bolder mt-4">
        Benefits of Using PayZeus Authorized Stores
        </h5>
        <ul>
          <li className="p-2 m-0">Wide Coverage: Our authorized stores are strategically located to serve both
          urban and rural areas, ensuring that everyone has access to PayZeus services.</li>
          <li className="p-2 m-0">Security and Trust: Transactions at authorized stores are secure, with all
          partners undergoing rigorous verification to ensure reliability.</li>
          <li className="p-2 m-0">Immediate Availability: Recharged funds are available in your PayZeus account
          immediately, enabling you to make instant payments and transfers.</li>
        
          
        </ul>


        <h5 className="fw-bolder mt-4">
        Security Measures
        </h5>
        <strong>Your security is our priority. PayZeus employs advanced security protocols to protect
        your transactions at authorized stores:</strong>
     
        <ul>
          <li className="p-2 m-0">Verified Partners: Only authorized and verified partners can provide recharge
          services for PayZeus accounts.</li>
          <li className="p-2 m-0">Instant Notifications: Receive instant SMS or email notifications confirming your
          recharge, ensuring transparency.</li>
          <li className="p-2 m-0">Transaction Receipts: Always receive and keep a receipt of your transaction for
          verification and record-keeping.</li>
        
          
        </ul>
     
      </div>

      
      <Footer />
    </div>
  );
}

export default AuthorizedStores;
