import React,{useEffect} from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import "./../Styles/faq.css";
function Faq() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  
  return (
    <div id="Faq">
     
      <Navbar />








      <div className="container mb-4 py-5">
        <div className="row">
          <div className="col-lg-12 p-0">

            <h3 className="fw-bolder mt-4 ">Frequently Asked Questions</h3>
           

          </div>
          <div className="col-lg-12 p-0">

            <h5 className="fw-bolder mt-4 ">What is PayZeus?</h5>
           
          PayZeus is a digital banking platform that offers a range of financial services, including account management, payments, transfers, and more.
          <h5 className="fw-bolder mt-4 ">How can I sign up for PayZeus?</h5>
          You can sign up for PayZeus by downloading the mobile app from the App Store or Google Play Store. Follow the on-screen instructions to create your account.
          <h5 className="fw-bolder mt-4 ">Is PayZeus secure?</h5>
          Yes, PayZeus prioritizes the security and privacy of our users. We employ advanced encryption and security measures to protect your financial information.
        
          <h5 className="fw-bolder mt-4 ">What services does PayZeus offer?</h5>
          PayZeus offers various services, including e-Wallet cash management, fund transfers, bill payments, digital debit and credit cards, savings accounts, loans, and more.
        
          <h5 className="fw-bolder mt-4 ">Can I recharge my PayZeus account using cash?</h5>
Yes, you can recharge your PayZeus account using cash at authorized stores and street vendors.
<h5 className="fw-bolder mt-4 ">How do I contact PayZeus customer support?</h5>
You can contact PayZeus customer support through the app or website. We also provide email and phone support for assistance with any inquiries or issues.


<h5 className="fw-bolder mt-4 ">Can I use PayZeus internationally?</h5>
Yes, will be available for use internationally, with support for multiple currencies and languages.
<h5 className="fw-bolder mt-4 ">How do I report unauthorized activity on my account?</h5>

If you notice any unauthorized activity on your PayZeus account, contact our customer support immediately to report the issue and take necessary action to secure your account.

<h5 className="fw-bolder mt-4 ">Are there any fees for using PayZeus?</h5>

PayZeus offers free account opening and maintenance for individuals. Refer to our fee schedule for more information.

<h5 className="fw-bolder mt-4 ">Does PayZeus offer rewards or loyalty programs?</h5>
Yes, PayZeus offers rewards, points, and promotional offers for eligible account holders, including cash-back rewards on purchases and special deals from participating merchants.
        
        
       

          </div>
        </div>
      </div>


      <Footer />

    </div>
  );
}

export default Faq;
