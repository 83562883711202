import React,{useEffect} from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import '../Styles/rechargepage.css'

import {
  
  Link,
} from "react-router-dom";
function QrScanning() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Navbar />


      <div className="container pt-5">
      
      <div className="row">
        <div className="col-md-12">
        <h3 className="fw-bolder mt-4">QR Scanning</h3>
        <strong>Recharge with QR Scanning: Quick and Easy at PayZeus</strong>
        <p>At PayZeus, we are committed to providing you with the most convenient and efficient
ways to manage your digital finances. One of the fastest and most secure methods to
recharge your PayZeus account is by scanning a QR code. This modern approach
ensures that your account is always ready for instant transactions without any hassle.</p>
        <h5 className='fw-bolder mt-4' >Why Choose QR Scanning for Recharge?</h5>
        <strong>Using QR codes to recharge your PayZeus account offers numerous benefits:</strong>
       
        <ul>
          <li className="p-2 m-0">Speed: QR scanning is one of the quickest methods to recharge your account.
          Simply scan the code, and your funds are transferred instantly.</li>
          <li className="p-2 m-0">Convenience: No need to manually enter any details. Just scan and go, making it
          perfect for on-the-go recharges.</li>
          <li className="p-2 m-0">Security: QR code transactions are secure, reducing the risk of errors or fraud.
          Your information is encrypted, ensuring safe transfers.</li>
          <li className="p-2 m-0">Accessibility: QR scanning can be done from any mobile device with a camera,
          making it a versatile option for all users.</li>
          
        </ul>
              
        </div>

        
      </div>
      
      
      </div>




      <div className="container pb-5">
        
        <h5 className="fw-bolder mt-4">
        How to Recharge Your PayZeus Account by Scanning a QR Code?
        </h5>
        <strong>Recharging your PayZeus account with a QR code is straightforward. Follow
        these simple steps:</strong>
        <ul>
          <li className="p-2 m-0">Log In to Your PayZeus Account: Access your account through the PayZeus
          website or mobile app.
</li>
          <li className="p-2 m-0">Navigate to the Recharge Section: Go to the recharge section within your
          account dashboard.
</li>
          <li className="p-2 m-0">Select QR Code as Your Payment Method: Choose the option to recharge using
          a QR code.Select QR Code as Your Payment Method: Choose the option to recharge using
          a QR code.
</li>
          <li className="p-2 m-0">Scan the QR Code: Use your mobile device’s camera to scan the QR code
          provided. Ensure the code is clear and within the frame for accurate scanning.

</li>
<li className="p-2 m-0">Confirm the Amount: Verify the amount you wish to transfer and confirm the
transaction.

</li>
<li className="p-2 m-0">Complete the Transaction: Once confirmed, the funds will be instantly added to
your PayZeus account.

</li>

        </ul>
        <h5 className="fw-bolder mt-4">
        Supported Payment Platforms
        </h5>
        <p>PayZeus supports QR code payments from a variety of platforms, ensuring you can recharge your account using your preferred method. This includes compatibility with major payment apps and banking services that offer QR code transactions.</p>

        <h5 className="fw-bolder mt-4">
        Security Measures
        </h5>
        <strong>At PayZeus, your security is our top priority. We implement advanced encryption
        and secure protocols to protect your transactions. Key security features include:</strong>
        <ul>
          <li className="p-2 m-0">Secure Data Encryption</li>
          <li className="p-2 m-0">Real-time Fraud Monitoring</li>
          <li className="p-2 m-0">Two-Factor Authentication (2FA)</li>
        
          
        </ul>


        <h5 className="fw-bolder mt-4">
        Get Started Today!
        </h5>
        <p>Recharging your PayZeus account by scanning a QR code is a fast, secure, and
convenient way to ensure your funds are always ready for use. Log in to your PayZeus
account today and experience the simplicity of QR code recharges.</p>
      </div>

      
      <Footer />
    </div>
  );
}

export default QrScanning;
