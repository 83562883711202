import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'
import './../Styles/openindividual.css'

function DigitalDebitCards() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='DigitalDebitCards'>
    <Navbar />
   <div className="container py-5">

   <h3 className='fw-bolder mt-4' >Digital Debit Cards</h3>
   <h5 className='fw-bolder mt-4' >Discover the Future of Payments with PayZeus Digital Debit Cards</h5>
   <h5 className='fw-bolder mt-4' >Introduction to PayZeus Digital Debit Cards</h5>
   <p>Welcome to the future of finance with PayZeus Digital Debit Cards. Our innovative virtual debit cards offer unparalleled convenience and security for all your transactions. Whether you're shopping online or managing your expenses, our digital debit cards are the perfect solution.</p>

   <h4 className='fw-bolder mt-4' > Benefits of Using Digital Debit Cards</h4>
  
   <h5 className='fw-bolder mt-4' > Convenience and Accessibility</h5>
   <p>With PayZeus Digital Debit Cards, you can access your funds anytime, anywhere. No need to carry physical cards or cash. Simply use your digital debit card through your e-wallet for seamless transactions.</p>
       

   <h4 className='fw-bolder mt-4' >Benefits of Using Digital Debit Cards</h4>
  
  <h5 className='fw-bolder mt-4' >Enhanced Security</h5>
<p>Security is a top priority at PayZeus. Our digital debit cards use advanced encryption technology to protect your information. You can shop with confidence knowing your data is safe.</p>      
      
  <h5 className='fw-bolder mt-4' >Instant Issuance</h5>
  <p>Get your virtual debit card instantly upon approval. No waiting for physical cards to arrive in the mail. Start using your digital debit card immediately for real-time payment.</p>
  <h5 className='fw-bolder mt-4' >Real-Time Payment Processing</h5>
        <p>
Experience the efficiency of real-time payment with PayZeus Digital Debit Cards. Whether you're paying bills or transferring money, enjoy instant processing for all transactions.
</p>
<h4 className='fw-bolder mt-4' >How to Get Started with PayZeus Digital Debit Cards</h4>
<h5 className='fw-bolder mt-4' >Easy Sign-Up Process</h5>

<ul className='open_individual_account_order_list' >
            <li className='p-2 m-0' >Visit the PayZeus website.</li>
            <li className='p-2 m-0' >Sign up for an account.</li>
            <li className='p-2 m-0' >Apply for your digital debit card.</li>
            <li className='p-2 m-0' >Verify your identity.</li>
            <li className='p-2 m-0' >Start using your card immediately.</li>
          
         </ul>
         <h5 className='fw-bolder mt-4' >Linking to Your E-Wallet</h5>
         <p>Link your virtual debit card to your preferred e-wallet for added convenience. Manage all your transactions from a single platform, making your financial life simpler and more organized.</p>
<p>PayZeus Digital Debit Cards are the ultimate tool for modern financial management. Enjoy the convenience, security, and real-time payment capabilities of our innovative digital debit cards. Sign up today and take control of your finances with PayZeus.
For more information, visit our website or contact our customer support team. Embrace the future of payments with PayZeus Digital Debit Cards.
</p>
        </div>
   <Footer />
</div>
  )
}

export default DigitalDebitCards;
