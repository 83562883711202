import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

import './../Styles/openindividual.css'

function OpenaCurrentAccount() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='OpenaCurrentAccount'>
    <Navbar />
   <div className="container py-5">

   <h3 className='fw-bolder mt-4' >Open a Current Account</h3>

   <h6 className='fw-bolder mt-4' >Unlock Financial Freedom with PayZeus: Your Comprehensive Banking Solution</h6>
   <p>At PayZeus, we offer a variety of accounts tailored to meet your financial needs. Open a Current Account for your daily transactions, a Fixed Deposit Account for your long-term savings, and Flexible Savings Accounts to help you manage your finances efficiently. Our account options are designed to provide you with the flexibility and security you need to achieve your financial goals.</p>

   <h5 className='fw-bolder mt-4' >Enjoy the Benefits of Digital Debit and Credit Cards</h5>
   <p>With PayZeus, you can own digital debit and credit cards that offer convenience and security for all your transactions. Our digital cards are easy to manage through our user-friendly app, providing you with real-time access to your account balance and transaction history. Enjoy the flexibility of making purchases, both online and offline, with the confidence that your financial information is protected.</p>



       <h5 className='fw-bolder mt-4' >Qualify for Education and Emergency Loans</h5>
       <p>PayZeus understands that life's unexpected moments require financial support. That's why we offer a range of loan options to meet your needs. Qualify for education loans to invest in your future or secure emergency loans to cover unforeseen expenses. Our loan application process is straightforward and designed to provide you with the funds you need, when you need them.</p>
       <h5 className='fw-bolder mt-4' >Take Advantage of BNPL Facilities</h5>
       <p>Our Buy-Now-Pay-Later (BNPL) facilities allow you to make purchases and spread the cost over time. Whether you're shopping for essentials or treating yourself to something special, PayZeus makes it easier to manage your budget with flexible repayment options. Enjoy the convenience of buying now and paying later, all while keeping your finances under control.</p>
<h4 className='fw-bolder mt-4' >Why Choose PayZeus?</h4>
<p>PayZeus is committed to providing comprehensive financial solutions that cater to your unique needs. From opening various types of accounts to managing your spending with digital cards, and securing loans for education or emergencies, PayZeus is your trusted partner in financial management. Our services are designed to be accessible, convenient, and secure, ensuring that you have the support you need to navigate your financial journey.</p>
       
<h5 className='fw-bolder mt-4' >Get Started with PayZeus Today</h5>
<p> Getting started with PayZeus is simple. Download our app, register your account, and explore the wide range of financial services we offer. Whether you're looking to open an account, apply for a loan, or take advantage of our BNPL facilities, PayZeus is here to help you every step of the way.

</p>
<h5 className='fw-bolder mt-4' >Empower Your Financial Future with PayZeus</h5>
<p>PayZeus offers a comprehensive suite of financial services designed to meet your diverse needs. Open a current or savings account, manage your money with digital cards, and access loans and BNPL facilities with ease. Choose PayZeus for a seamless, secure, and convenient banking experience. Empower your financial future with PayZeus, your partner in financial success.

Choose PayZeus and take control of your financial destiny today.</p>
        </div>
   <Footer />
</div>
  )
}

export default OpenaCurrentAccount;
