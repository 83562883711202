import React,{useEffect} from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import '../Styles/rechargepage.css'

import {
  
  Link,
} from "react-router-dom";
function PayZeusAuthorizedStores() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='PayZeusAuthorizedStores'>
    <Navbar />
   <div className="container py-5">

   <h3 className='fw-bolder mt-4' >Authorized Stores | PayZeus</h3>

   <h6 className='fw-bolder mt-4' >Recharge Your PayZeus Account at Authorized Stores</h6>
   <p>At PayZeus, we are dedicated to making digital banking accessible and convenient for everyone. We provide a reliable and secure way to recharge your PayZeus account through our extensive network of authorized stores. This service ensures that you can top up your account using cash or other payment methods at locations close to you.</p>

   <h5 className='fw-bolder mt-4' >How to Recharge at Authorized Stores</h5>
   <p><strong>
   Recharging your PayZeus account at an authorized store is simple and straightforward. Follow these steps:</strong></p>


       <h5 className='fw-bolder mt-4' >Locate an Authorized Store</h5>
       <p>Use the PayZeus website or mobile app to find the nearest authorized store in your area. Our network spans across India, Pakistan, Afghanistan, and Bangladesh, ensuring that you can find a convenient location.

</p>
       
<h5 className='fw-bolder mt-4' >Visit the Store</h5>
<p>Go to the selected authorized store with the amount of cash you wish to recharge.

</p>
<h5 className='fw-bolder mt-4' >Provide Your PayZeus Account Details</h5>
<p>Inform the store staff of your registered PayZeus mobile number, which acts as your account identifier.</p>
<h5 className='fw-bolder mt-4' >Hand Over the Cash</h5>
<p>Give the cash to the store representative and specify the amount you want to add to your PayZeus account.</p>
<h5 className='fw-bolder mt-4' >Confirm the Transaction</h5>
<p>The store representative will process your request, and the funds will be credited to your PayZeus account instantly. You will receive a receipt as proof of the transaction.

</p>
        
        
        </div>
   <Footer />
</div>
  )
}

export default PayZeusAuthorizedStores;
