import React from "react";
import "../Styles/bankingsolution.css";
import {
  
  Link,
} from "react-router-dom";

function BankingSolution() {
  return (
    <>
      <div className="bankingsolution">
        <div className="container">
          <h3 className="text-center fw-bold">
            All-In-One Banking and Payment <span>Solution For EveryOne</span>
          </h3>
          <div className="row">
            <div className="col-md-4 my-5">
              <div className="bankingsolution-main">
                <img src="/images/banking1.png" alt="" />
                <h4 className="mt-3">Requires No Formal Bank Account</h4>
                <p>
                  You don’t need to have an account at a commercial bank to open
                  a PayZeus Account, but you can link all your other account(s)
                  to your PayZeus Account!
                </p>
                <button><Link to="/requires-no-formal-bank-account" style={{ color: 'white', textDecorationLine:'none' }}>Read More</Link></button>
              
              </div>
            </div>
            <div className="col-md-4 my-5">
              <div className="bankingsolution-main">
                <img src="/images/banking2.png" alt="" />
                <h4 className="mt-3">No Age Limit to Open a PayZeus Account</h4>
                <p>
                  As long as you have a registered mobile in Your Name, you are
                  eligible to have your own Personal PayZeus Account!
                </p>
              
                <button><Link to="/no-age-limit" style={{ color: 'white', textDecorationLine:'none' }}>Read More</Link></button>
              </div>
            </div>
            <div className="col-md-4 my-5">
              <div className="bankingsolution-main">
                <img src="/images/banking3.png" alt="" />
                <h4 className="mt-3">All Transactions in Real Time!</h4>
                <p>
                  All Payments, in-ward Transfers, Deposits, Balance Enquiries,
                  and Expenditure Reporting are in Real-Time!
                </p>
                <br></br>
                <button><Link to="/all-transactions-Home" style={{ color: 'white', textDecorationLine:'none' }}>Read More</Link></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BankingSolution;
