import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './../Styles/cookieModal.css'


function CookieModal({showCookieModal,setShowCookieModal}) {
  return (
    <div>
       <Modal
        size="xl"
        show={showCookieModal}
        centered={true}
        // onHide={() => setShowCookieModal(false)}
      >
        <Modal.Body>
            <div className='cookie_modal text-center' >
                <img src="/images/loader.png" alt="img" />
                <h4>Statement of Facts and Intentions</h4>
                <p>The PayZeus.net website you have accessed presents the features and characteristics of a Unique
Digital Banking and Real-Time Payment (RTP) system that runs on a new, alternative, digital, and
not-cryptocurrency. This closed-loop System involves you, as the account holders by merely owning
a registered mobile telephone number; a highly safe and efficient application platform that runs
independent of any commercial bank but on web as well as on all existing, and future, mobile
operating systems; an independent, licensed network of selling PayZeus Vouchers online, at store
counters, and by street vendors; a regulated bank to fully underwrite your deposits in your local
currency while being held accountable by your monetary and deposit insurance statutory authorities;
a network of like-minded individuals, businesses, and government agencies that will be transacting
amongst themselves; and a network of ATMs and other tools to convert your Zeus holdings back
into your local currency, if you wish to do so for any reason whatsoever!</p>

<p>The application is being developed by a team of professional technologists and bankers, and legal
advisors. All technical, logistical, and legal underpinning of the system are also being addressed in
parallel, including the establishment of the ZeusBank, with the view to making the Zeus Network
available to the international public, very soon!</p>
                <p><b>Thank you!</b></p>
                <div className='cookie_modal_logo' >
                   <img src="/images/logo.png" alt="img" />
                   <h6><i>Safety, Security, Efficiency</i></h6>
                   <p>Note: PayZeus and ZeusBank, and their associated logos are
                   trademarks of macroeconsulting (USA).</p>
                   <button onClick={() => setShowCookieModal(false)} >Continue</button>
                </div>
              
               
            </div>
        </Modal.Body>
      </Modal>
      
    </div>
  )
}

export default CookieModal
