import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

import './../Styles/openindividual.css'

function NoAgeLimit() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='NoAgeLimit'>
    <Navbar />
   <div className="container py-5">

   <h3 className='fw-bolder mt-4' >No Age Limit to Open a PayZeus Account</h3>
   <p>As long as you have a registered mobile in Your Name, you are eligible to have your own Personal PayZeus Account!
At PayZeus, We believe that financial freedom should be available to everyone, regardless of age. As long as you have a registered mobile in your name, you are eligible to open your very own PayZeus account!
</p>

   <h5 className='fw-bolder mt-4' >Why Choose PayZeus?</h5>

   
<h6 className='fw-bolder mt-4' >Inclusive Accessibility</h6>
<p>No age restrictions mean anyone can enjoy the benefits of a PayZeus account. Whether you are a young adult just starting your financial journey or someone with years of experience, PayZeus is here for you.</p>

<h6 className='fw-bolder mt-4' >Easy Eligibility</h6>
<p>If you have a registered mobile number in your name, you qualify for a PayZeus account. It's that simple. No complex requirements, just straightforward accessibility to modern financial tools.</p>

<h5 className='fw-bolder mt-4' >Features of Your Personal PayZeus Account</h5>

<ul className='open_individual_account_order_list' >
            <li className='p-2 m-0' >Secure Transactions: Enjoy peace of mind with our advanced security measures.</li>
            <li className='p-2 m-0' >User-Friendly Interface: Navigate easily with our intuitive platform.</li>
            <li className='p-2 m-0' >Financial Management Tools: Track your expenses, set goals, and manage your money efficiently.</li>
           
          
         </ul>
         <h5 className='fw-bolder mt-4' >Get Started Today</h5>
         <p>Join the PayZeus community and take control of your financial future. Open your PayZeus account today, and experience the freedom of managing your finances without limitations.</p>
        
        <a href='#'>Sign up Now</a>
        </div>
   <Footer />
</div>
  )
}

export default NoAgeLimit;
