import React, { useEffect } from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";





function News() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  return (
    <div id="News">
      <Navbar />








      <div className="container mb-4 py-5">
        <div className="row">
          <div className="col-lg-12 p-0">
          

            <h3 className="fw-bolder mt-4 ">News</h3>

            <h6 className="fw-bolder mt-4 ">Find the latest news from the links provided below</h6>
            {<br/>}
          <p> <a href="https://www.paymentsdive.com/" target="_blank">https://www.paymentsdive.com/</a>
            <br></br>
<a href="https://www.bankingdive.com/" target="_blank">https://www.bankingdive.com/</a>
          
          </p>

          

          </div>
        </div>
      </div>


      <Footer />

    </div>
  );
}

export default News;
