import React from "react";
import "../Styles/specialoffer.css";
import {
  
  Link,
} from "react-router-dom";
function SpecialOffer() {
  return (
    <div className="specialoffer">
      <div className="container">
        <div className="row">
          <div className="specialoffer-image-main col-md-7 d-flex align-items-center">
            <div className="specialoffer-main w-sm-0">
              <span>Get PayZeus now</span>
              <h2 className="mb-3">
                PayZeus Offers Attractive “Rewards/Points/Offers”
              </h2>
              <p>
              PayZeus Individual Account Holders will receive points and rewards, including cash-back, on their purchases using their PayZeus Digital Debit Card and Digital Credit Cards from participating stores!  They could also benefit from redeeming various generous promotional offers made by the participating merchants.  These vouchers and promotion offers are posted on both PayZeus Application and PayZeus website, and differ from county-to-country, city-to-city, and even location-to-location, and include targeted, using a custom-made AI program, offers based on the individual’s shopping preferences.    
              </p>

              <div className="my-4">
              <button className="specialoffer-learn-btn"><Link to="/offers-attractive-rewards" style={{ color: 'white', textDecorationLine:'none' }}>Get Started</Link></button>
                
              </div>
            </div>
          </div>
          <div className=" col-md-5 d-flex justify-content-center align-items-center">
            <div className="specialoffer-image">
              <img src="/images/shoping-coupon.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpecialOffer;
