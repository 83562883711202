import React,{useEffect} from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import "./../Styles/aboutus.css";
function OurValues() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  
  return (
    <div id="OurValues">
      <Navbar />








      <div className="container mb-4 py-5">
        <div className="row">
          <div className="col-lg-12 p-0">

            <h3 className="fw-bolder mt-4">Our Values</h3>
           
            <ul className='open_business_account_order_list py-2' >

              <li className='p-2 m-0' >In God We Trust, the earth is our refuge, the world is our client, and the people are our strength.</li>
              <li className='p-2 m-0' >No discrimination at any level or on any basis, and we count everyone.</li>
              <li className='p-2 m-0' >An honest and dedicated service to every customer, around the clock, every day.</li>
             
            </ul>

          </div>
        </div>
      </div>







      <Footer />

    </div>
  );
}

export default OurValues;
