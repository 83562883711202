import React from "react";
import { BiRightArrowCircle } from "react-icons/bi";
import "../Styles/safeandsecure.css";

import {
  
  Link,
} from "react-router-dom";


function SafeAndSecure() {
  return (
    <div className="safeandsecure">
      <div className="safe-and-secure">
        <div className="container">
          <h3 className="text-center fw-bold">
            PayZeus is <span>Safe and Secure!</span>
          </h3>
          <p className="text-center safe-and-secure-para">
            Your Money is fully backed by ZeusBank , a licensed and regulated
            bank, and its branches in countries where PayZeus is welcome! Your
            Savings and Deposits are 100% insured by international insurance
            companies! Access to Your Account takes place only through Voice
            Recognition, and ALL Payments, and Transfers are verified by a OTP
            sent to your Mobile Phone. There is an Amount Cap for each
            Transaction and Repeated Entries per day! But No Limits on Number of
            Transactions Per Day!
          </p>
          <div className="safe-and-secure-main">
            <div className="row">
              <div className="col-md-4">
                <div className="safe-and-secure-section safe-border">
                  <img src="/images/safeandsecure1.png" alt="" />
                  <h5>Access by Voice Recognition</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="safe-and-secure-section safe-border">
                  <img src="/images/safeandsecure2.png" alt="" />
                  <h5>OTP-Verified Payments and Transfers</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="safe-and-secure-section">
                  <img src="/images/safeandsecure3.png" alt="" />
                  <h5>Powered by AI</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="safe-and-secure-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="safeandsecure-image-main">
                <img src="/images/safeandsecure4.png" alt="image" />
                <div className="safeandsecure-box">
                  <h5 className="text-center fw-bold">
                    To Access Your Account
                  </h5>
                  <p className="text-center">
                    You need to first Register Your Voice on PayZeus
                    Application; PayZeus uses only Voice Recognition to Allow
                    Access!
                  </p>
                  <button>
                  {/* <BiRightArrowCircle />*/} 
                    <Link to="/to-access-your-account" style={{ color: 'white', textDecorationLine:'none' }}>Get Started</Link>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="safe-and-secure-2-left d-flex flex-sm-row flex-column justify-content-between">
                <img src="/images/safeandsecure5.png" alt="images" />
                <div className="safe-and-secure-2-section py-sm-0 py-3 ">
                  <h5 className="text-start fw-bold">
                    It is Efficient and Easy to Use
                  </h5>
                  <p className="text-start">
                  3 Easy Steps to Recharge Your Account, either in English or in Your Local Language!
                  </p>
                  <button>
                     {/* <BiRightArrowCircle />*/} 
                    <Link to="/efficient-easy-use" style={{ color: 'white', textDecorationLine:'none' }}>Get Started</Link>
                    
                  </button>
                  {/* <p>You could easily switch between English and Your Local Language by Clicking on the Select Language tab in the Application after you open your account!</p> */}
                </div>
              </div>

              <div className="safe-and-secure-2-left flex-sm-row flex-column d-flex justify-content-between">
                <img src="/images/safeandsecure6.png" alt="images" />
                <div className="safe-and-secure-2-section  py-sm-0 py-3 ">
                  <h5 className="text-start fw-bold">
                    It is Efficient and Easy to Use
                  </h5>
                 <ol>
                  <li>4 Easy Steps to Open Your Account</li>
                  <li>3 Easy Steps to Recharge Your Account</li>
                  <li>Easy to Understand Your Statement in One View</li>
                  <li>Easy to Monitor Your Expenditures by Category, and by Vendor!</li>
                  <li>Free-of-Charge to Open and Maintain Your Personal Account</li>
                  <li>Real-Time Payments and Transfers </li>
                 
                 </ol>
                  <button>
                   {/* <BiRightArrowCircle />*/} 
                    <Link to="/efficient-easy-use-steps" style={{ color: 'white', textDecorationLine:'none' }}>Get Started</Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SafeAndSecure;
