import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

import './../Styles/openindividual.css'

import {
  
  Link,
} from "react-router-dom";

function UniqueDigitalBanking() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='UniqueDigitalBanking'>
    <Navbar />
   <div className="container py-5">

   <h3 className='fw-bolder mt-4' >PayZeus is A Unique Digital
Banking & Payment
System</h3>

   <h5 className='fw-bolder mt-4' >Instant Access to Credit</h5>
   <div className="col-md-12 d-flex align-items-center">
                   <div className="banner-main">
                   <h2>PayZeus is A Unique Digital </h2>
                   <h2>Banking & Payment</h2>
                   <h2> System</h2>
                   <h4 className="typer-text">
                   A Complete Banking Solution combined with a Real Time Payment (RTP) platform!
                   {/* &nbsp;
                <Typed
                  style={{ color: "#343C5C" }}
                  strings={[
                    "Saving a/c",
                    "FD",
                    "Forex",
                  ]}
                  typeSpeed={80}
                  backSpeed={10}
                  loop
                /> */}
              </h4>
                    <p>No Formal Application, No Bank Account, No Credit Card, No Credit History…</p>
                    <p>Only Your Registered Mobile Number!</p>
                   
                   <p>In the rapidly evolving landscape of digital finance, PayZeus emerges as a trailblazer, revolutionizing the way we perceive and interact with banking and payment systems. With its innovative approach, PayZeus integrates a comprehensive banking solution with a Real-Time Payment (RTP) platform, making financial transactions seamless and efficient. This article delves into the unique features and benefits of PayZeus, positioning it as the ultimate choice for modern banking and payment needs.</p>
                   
                   <h5 className='fw-bolder mt-4' >A Complete Banking Solution</h5>
                   <p>PayZeus is not just another digital payment platform; it is a complete banking solution designed to cater to all your financial needs. Whether you need to manage your savings, make investments, or handle everyday transactions, PayZeus offers a one-stop solution. Here are some standout features:</p>
                   
                   
                   <ul className='open_individual_account_order_list' >
            <li className='p-2 m-0' >No Formal Application Required: Unlike traditional banks, PayZeus eliminates the need for lengthy and complicated application processes.</li>
            <li className='p-2 m-0' >No Bank Account Needed: You don't need an existing bank account to use PayZeus. This makes it accessible to a broader audience, including those who are unbanked.</li>
            <li className='p-2 m-0' >No Credit Card or Credit History: PayZeus does not require you to have a credit card or a credit history, making it an inclusive platform for all.</li>
            
          
         </ul>
         <h5 className='fw-bolder mt-4' >Real-Time Payment Platform</h5>
         <p>The Real-Time Payment (RTP) platform is at the core of PayZeus. It ensures that all transactions are processed instantly, providing unparalleled convenience and efficiency. Whether you are transferring money to a friend or paying for goods and services, the RTP platform guarantees that the funds are available immediately.</p>
                   </div>
                   </div>
 
        </div>
   <Footer />
</div>
  )
}

export default UniqueDigitalBanking;
