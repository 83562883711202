import React,{useEffect} from "react";
import Navbar from "../sections/Navbar";
import Footer from "../sections/Footer";
import '../Styles/rechargepage.css'

import {
  
  Link,
} from "react-router-dom";
function UsingCreditCards() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Navbar />


      <div className="container pt-5">
      
      <div className="row">
        <div className="col-md-12">
        <h3 className="fw-bolder mt-4">Using Credit Cards</h3>
        <h5 className='fw-bolder mt-4' >Recharge with Credit Cards: Fast and Convenient at PayZeus</h5>
        <p>In today&#39;s fast-paced digital world, convenience is key. PayZeus offers you a seamless
and efficient way to recharge your PayZeus account using credit cards. Whether you&#39;re
at home, at work, or on the go, recharging your account is quick and straightforward.</p>
        
              
        </div>

        
      </div>
      
      
      </div>






      <div className="container pb-5">
        
        <h5 className="fw-bolder mt-4">
        Why Recharge with Credit Cards?
        </h5>
        <strong>Using your credit card to recharge your PayZeus account provides several
        benefits:</strong>
        <ul>
          <li className="p-2 m-0">Instant Transactions: Recharging with a credit card ensures that your funds are
available instantly, allowing you to make real-time payments and transfers
without any delay.</li>
          <li className="p-2 m-0">Convenience: You can recharge your PayZeus account from anywhere, at any
          time. All you need is your credit card and an internet connection.</li>
          <li className="p-2 m-0">Security: PayZeus employs advanced security measures to protect your
transactions and personal information. Our platform ensures that your credit card
details are secure and that your funds are safe.</li>
          <li className="p-2 m-0">Flexibility: Credit cards offer a flexible payment option, allowing you to manage
your finances more effectively. You can use any major credit card to recharge
your account.</li>
          
        </ul>

        <h5 className="fw-bolder mt-4">How to Recharge Your PayZeus Account Using a Credit Card?</h5>
        <strong>Recharging your PayZeus account with a credit card is simple and hassle-free.
        Follow these easy steps:</strong>
        <ul>
          <li className="p-2 m-0">Log In to Your PayZeus Account: Access your PayZeus account by logging in
          through the website or mobile app.
</li>
          <li className="p-2 m-0">
          Navigate to the Recharge Section: Once logged in, go to the recharge section in
your account dashboard.

          </li>
          <li className="p-2 m-0">Select Credit Card as Your Payment Method: Choose credit card as your
          preferred payment method from the available options.
</li>
          <li className="p-2 m-0">
          Enter Your Credit Card Details in your eWallet: Provide your credit card
          information, including card number, the name on the card, expiry date, and CCV!

          </li>
          <li className="p-2 m-0">
          Confirm the Amount to Recharge: Specify the amount you wish to add to your
          PayZeus account.

          </li>
          <li>
          Complete the Transaction: Review your details and confirm the transaction. Your
          PayZeus account will be credited instantly.

          </li>
        </ul>

        <h5 className="fw-bolder mt-4">
        Supported Credit Cards
        </h5>
        <strong>PayZeus supports all major credit cards, ensuring that you have multiple options
        to recharge your account. These include:</strong>
        <ul>
          <li className="p-2 m-0">Visa</li>
          <li className="p-2 m-0">Mastercard</li>
          <li className="p-2 m-0">American Express</li>
          <li className="p-2 m-0">Discover</li>
          
        </ul>


        <h5 className="fw-bolder mt-4">
        Get Started Today!
        </h5>
        <p>Recharging your PayZeus account with a credit card is the fastest and most convenient
way to ensure you always have funds available for your transactions. Log in to your
PayZeus account today and experience the ease and security of recharging with your
credit card.</p>
      </div>

      
      <Footer />
    </div>
  );
}

export default UsingCreditCards;
