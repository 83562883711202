import React,{useEffect} from 'react'
import Navbar from '../sections/Navbar'
import Footer from '../sections/Footer'

import './../Styles/openindividual.css'
import {
  
  Link,
} from "react-router-dom";

function TermsAndConditions() {

  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div id='TermsAndConditions'>
    <Navbar />
   <div className="container py-5">

   <h3 className='fw-bolder mt-4' >Terms of Service</h3>
   <h6 className='fw-bolder mt-4' > Effective Date: 8/8/2024</h6>
   <p>Welcome to PayZeus. By accessing or using our services, you agree to be bound by the following terms and conditions (the "Terms"). Please read them carefully.</p>
   <h6 className='fw-bolder mt-4' > 1. Acceptance of Terms</h6>
<p>By using PayZeus services, you agree to comply with these Terms. If you do not agree to these Terms, you may not use our services.</p>
<h6 className='fw-bolder mt-4' >2. Services</h6>
<p>PayZeus offers digital banking and payment services including e-wallets, transfers, payments, savings, credit facilities, and ATMs. Additional services may be added in the future.</p>
       
<h6 className='fw-bolder mt-4' >3. User Obligations</h6>
<p>You agree to provide accurate and complete information when creating an account with PayZeus. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

      <h6 className='fw-bolder mt-4' >4. Fees</h6>
      <p>PayZeus may charge fees for certain services. The applicable fees will be disclosed prior to the completion of any transaction.</p>
       
      <h6 className='fw-bolder mt-4' >5. Prohibited Activities</h6>
      <p>Users are prohibited from engaging in any activity that violates any laws or regulations, infringes on the rights of others, or interferes with the operation of PayZeus services.</p>
       
      <h6 className='fw-bolder mt-4' >6. Termination</h6>

      <p>PayZeus reserves the right to suspend or terminate your access to our services at any time for any reason, including violation of these Terms.</p>

      <h6 className='fw-bolder mt-4' >7. Limitation of Liability</h6>
      <p>PayZeus is not liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our services.</p>
       
       
      <h6 className='fw-bolder mt-4' >8. Governing Law</h6>
      <p>These Terms are governed by the laws of the State of California, USA. Any disputes arising from these Terms will be resolved in the courts of California.</p>
      
      <h6 className='fw-bolder mt-4' >9. Contact Information</h6>
      <p>If you have any questions about these Terms, please contact us at info@payzeus.net.</p>
        </div>
   <Footer />
</div>
  )
}

export default TermsAndConditions;
